<template>
  <div class="text-white border-t-4 border-blue-500 modal">
    <div class="px-4 py-5 bg-gray-700 border-b border-gray-800 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-slate-100">
        Rename Previz
      </h3>
    </div>

    <div class="px-8 py-4 modal-content bg-zinc-900">
      <form
        :class="{ error: form.errors.get('form'), error: formFormHasErrors }"
        @submit.prevent="submit"
      >
        <div class="relative mt-1 rounded-md shadow-sm">
          <input
            ref="input"
            v-model="name"
            type="text"
            class="block w-full bg-black border-gray-200 rounded-md shadow-sm placeholder-neutral-400 focus:placeholder-neutral-100 text-neutral-400 focus:text-neutral-100 focus:bg-neutral-900 focus:ring-indigo-500 focus:border-indigo-500 focus-within:border-transparent focus-within:ring-2 focus-within:ring-indigo-600"
            name="name"
            autofocus
          />
        </div>
        <div class="flex items-center justify-end mt-6 btn-group">
          <button class="text-white btn" type="button" @click="$emit('close')">
            Cancel
          </button>
          <button
            class="btn primary disabled:opacity-50 disabled:cursor-not-allowed"
            type="button"
            @click.prevent="submit"
            :disabled="!readyToSubmit"
          >
            Rename
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrevizRename',

  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: new SparkForm({
        name: null
      }),
      name: this.asset.name,
      formFormHasErrors: false
    }
  },
  computed: {
    readyToSubmit: function () {
      // Can't use reactivity on SparkForms, so we have to compute this externally
      return this.name !== ''
    }
  },

  watch: {
    asset: {
      handler(asset) {
        this.form.name = asset.name
      },
      immediate: true
    },
    name: function (name) {
      this.form.name = name
    }
  },

  mounted() {
    this.$refs.input.focus()
  },

  methods: {
    submit() {
      if (this.readyToSubmit) {
        this.formFormHasErrors = false
        this.form.startProcessing()
        this.$store
          .dispatch('assets/rename', {
            asset: this.asset,
            name: this.form.name
          })
          .then((result) => {
            if (result) {
              this.form.finishProcessing()
              this.$emit('close')
              alert.success('Previz renamed')
            } else {
              alert.error('Failed to rename previz')
              this.formFormHasErrors = true
              this.form.finishProcessing()
            }
          })
          .catch((response) => {
            alert.error('Failed to rename previz')
            this.formFormHasErrors = true
            this.form.setErrors(response.data)
            this.form.finishProcessing()
          })
      }
    }
  }
}
</script>
