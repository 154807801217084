<template>
  <div class="timeline-tabs flex select-none">
    <!-- Base Scene Tab -->
    <div
      :class="[ isBaseSceneActive ? 'shrink-0 text-gray-200 bg-gray-600 hover:bg-gray-600' : 'shrink text-gray-300 bg-gray-800 hover:bg-gray-700 text-gray-100' ]"
      class="flex flex-grow truncate justify-center items-center px-2 border-r border-gray-500 cursor-pointer hover:text-gray-200 font-bold focus:text-gray-200 active:text-gray-200"
    >
      <a
        href="#"
        class="flex-1 whitespace-nowrap py-1.5 px-1.5 font-medium text-xs leading-none truncate focus:outline-none focus:border-gray-300"
        @click.prevent="onSelectBaseScene"
      >
        <fa-icon
          icon="cube"
          class="fa-fw mr-2 text-xs text-orange-500"
        />
        <span class="">Base Scene</span>
      </a>
    </div>

    <!-- More Tabs -->
    <template v-for="sequence in sequences">
      <TimelineTopBarTab
        :viewer="viewer"
        :sequence="sequence"
        :active-id="activeSequencerId"
        :has-changes="hasChanges"
        @select="onSelect"
        @timeline-save-request="$emit('timeline-save-request', $event)"
      />
    </template>

    <div
      class="flex shrink text-gray-300 bg-gray-800 hover:bg-gray-700 text-gray-100 flex-grow truncate justify-center items-center px-2 py-1 border-r border-gray-500 cursor-pointer hover:text-gray-200 font-bold focus:text-gray-200 active:text-gray-200"
      @click.prevent="onNew"
    >
      <a
        href="#"
        class="whitespace-nowrap py-1.5 px-1.5 font-medium text-xs leading-5 text-gray-300 hover:text-gray-100 hover:border-gray-300 focus:outline-none focus:text-white focus:border-gray-300"
      >
        <fa-icon
          icon="plus"
          class="mx-1.5 cursor-pointer"
          size="xs"
        /> New
      </a>
    </div>
  </div>
</template>
<script>
import TimelineTopBarTab from '@components/editor/scene/panels/TimelineTopBarTab'
import TimelineTopBarEmptyTicks from '@components/editor/scene/panels/TimelineTopBarEmptyTicks'
import SequenceCreate from '@modals/SequenceCreate'
export default {

  components: {
    TimelineTopBarTab,
    TimelineTopBarEmptyTicks
  },

  props: {
    asset: {
      type: Object,
      required: true
    },
    sequences: {
      type: Array,
      required: true
    },
    viewer: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      activeId: null,
      nextRequestedActiveId: null
    }
  },

  computed: {

    isBaseSceneActive () {
      return !this.sequencerQueryId
    },

    timeline () {
      if (this.hasTimeline) return this.viewer.core.sequence.timeline
      return null
    },

    hasTimeline () {
      return (this.viewer && this.viewer.core && this.viewer.core.sequence && this.viewer.core.sequence.timeline)
    },

    hasNoSequences () {
      return this.sequences.length < 1
    },

    sequencerQueryId () {
      return this.$route.query.sequence
    },

    hasChanges () {
      if (this.timeline) return this.timeline.hasChanges
      return false
    },

    hasSequence () {
      return (this.viewer && this.viewer.core.sequence)
    },

    activeSequencerId () {
      if (this.hasSequence) return this.viewer.core.sequence.id
      return null
    },

    sequencerAsset () {
      if (this.activeId === null) return null
      return this.$store.getters['assets/getAssetById'](this.activeId)
    },

    validAsset () {
      return this.sequencerAsset !== null
    },

    url () {
      if (this.validAsset) return this.sequencerAsset.url
      return null
    },

    data () {
      if (this.validAsset) return this.$store.getters['sequencer/getDataById'](this.sequencerAsset.id)
      return null
    },

    sequence () {
      if (this.data !== null && this.data.sequence) return this.data.sequence
      return null
    },

    resources () {
      if (this.data !== null && this.data.resources) return this.data.resources
      return null
    },

    hasData () {
      return (this.sequence !== null && this.resources !== null)
    }
  },

  watch: {

    hasChanges: {
      handler (value) {
        if (value === false && this.nextRequestedActiveId !== null) {
          let id = this.nextRequestedActiveId
          this.nextRequestedActiveId = null
          this.$router.push({
            query: {
              sequence: id
            }
          })
        }
      }
    },

    sequences: {
      handler: function (value) {
        let active = null

        if (this.sequencerQueryId !== undefined) {
          let found = value.find(sequence => sequence.id === this.sequencerQueryId)
          if (found !== undefined) active = found
        }

        if (active !== null) {
          this.makeActive(active)
        }
      },
      immediate: true
    },

    hasData: {
      handler: function (value) {
        if (value === true) {
          this.initViewer()
        }
      },
      immediate: true
    },

    validAsset: {
      handler: function (value) {
        if (value === true) {
          this.loadSequence()
        }
      },
      immediate: true
    }
  },

  methods: {
    onNew () {
      if (this.timeline !== null) this.timeline.pause()

      this.$modal.show(
        SequenceCreate, {
          sceneId: this.asset.id,
          callback: this.onSelect
        }, {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    makeActive (sequence) {
      if (sequence.id !== this.activeId) {
        this.$bus.$emit('editor:inspector:clearactive')
        this.$store.dispatch('assets/loadAsset', {
          id: sequence.id,
          force: true
        })
          .then(() => {
            this.activeId = sequence.id
            this.loadSequence(sequence.id)
          })
      }
    },

    onSelectBaseScene () {
      this.$router.push({
        query: {}
      })
      this.unloadSequence()
    },

    onSelect (sequence) {
      if (this.hasChanges) {
        this.nextRequestedActiveId = sequence.id
        this.$emit('timeline-save-request')
      } else {
        this.makeActive(sequence)
        this.$router.push({
          query: {
            sequence: sequence.id
          }
        })
      }
    },

    loadSequence (id) {
      if (this.validAsset) {
        this.$store.dispatch('sequencer/loadFromUrl', {
          id: id,
          url: this.url
        })
      }
    },

    initViewer () {
      this.$nextTick(() => {
        let sequence = {}
        let resources = []
        if (this.sequence !== null) sequence = this.sequence
        if (this.resources != null) resources = this.resources

        this.viewer.core.loadSequence(sequence, resources)
        this.viewer.core.sequence.timeline.setReady()
      })
    },

    unloadSequence () {
      this.$bus.$emit('editor:inspector:clearactive')
      this.viewer.core.unloadSequence()
      this.activeId = null
    }
  }

}

</script>
