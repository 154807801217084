<template>
  <div
    :class="[
      isActive
        ? 'shrink-0 text-gray-200 bg-gray-600 hover:bg-gray-600'
        : 'shrink text-gray-300 bg-gray-800 hover:bg-gray-700 '
    ]"
    class="flex items-center justify-center flex-grow px-2 font-bold truncate border-r border-gray-500 cursor-pointer hover:text-gray-200 focus:text-gray-200 active:text-gray-200"
    @click.prevent="onSelect"
  >
    <a
      href="#"
      class="flex-1 whitespace-nowrap py-1.5 px-1.5 font-medium text-xs leading-none truncate focus:outline-none focus:border-gray-300"
    >
      <fa-icon
        v-if="busy"
        icon="spinner"
        spin
        size="lg"
        class="mr-2 text-sm text-gray-400 fa-fw"
      />
      <fa-icon
        v-else
        icon="film"
        size="lg"
        class="mr-2 text-sm text-gray-400 fa-fw"
      />
      <span :class="[showHasChanges ? 'font-bold' : '']"
        >{{ sequence.name }} <span v-if="showHasChanges">*</span></span
      >
    </a>
    <v-popover
      placement="bottom"
      offset="5"
      :popover-base-class="'tooltip popover  p-0'"
    >
      <button
        class="py-1 text-gray-400 rounded-full btn btn-no-style hover:text-gray-200 focus:outline-none focus:text-white"
        @click.prevent
      >
        <fa-icon icon="ellipsis-vertical" size="sm" />
      </button>

      <template slot="popover">
        <div class="w-56 text-white rounded-md shadow-lg">
          <div class="py-1">
            <a
              v-close-popover
              class="block px-4 py-2 text-sm leading-5 text-gray-100 cursor-pointer hover:bg-gray-800 hover:text-gray-200 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              @click.prevent="saveChanges"
            >
              <span class="block font-bold"
                >Save
                <span v-if="showHasChanges" class="text-gray-200"
                  >* (has unsaved changes)</span
                ></span
              >
              <span class="block text-xs text-gray-400">Autosave enabled</span>
            </a>
            <a
              v-close-popover
              class="block px-4 py-2 text-sm leading-5 text-gray-100 cursor-pointer hover:bg-gray-800 hover:text-gray-200 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              @click.prevent="toViewer"
            >
              <span class="block font-bold">Switch to View mode</span>
              <span class="block text-xs text-gray-400"
                >Read only mode for reviewing</span
              >
            </a>
            <a
              v-close-popover
              class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-gray-100 cursor-pointer hover:bg-gray-800 hover:text-gray-200 focus:outline-none focus:bg-gray-100 focus:text-gray-000"
              @click.prevent="onRename"
            >
              Rename <fa-icon icon="pencil" class="pl-1" />
            </a>
            <a
              v-close-popover
              class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-red-500 cursor-pointer hover:bg-gray-800 hover:text-red-500 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              @click.prevent="onDelete"
            >
              Delete <fa-icon icon="trash-can" class="pl-1" />
            </a>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>
<script>
import PrevizRenameModal from '@modals/PrevizRename'
import AssetDeleteModal from '@modals/AssetDelete'

export default {
  props: {
    sequence: {
      type: Object,
      required: true
    },
    activeId: {
      type: String,
      default: null
    },
    hasChanges: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      busy: false
    }
  },

  computed: {
    showHasChanges() {
      return this.isActive && this.hasChanges
    },

    isActive() {
      return this.activeId === this.sequence.id
    },

    project() {
      return this.$store.getters['projectsList/getProjectById'](
        this.projectSlug
      )
    },

    projectSlug() {
      return this.$route.params.slug
    }
  },

  watch: {
    activeId() {
      this.busy = false
    }
  },

  methods: {
    saveChanges() {
      this.$emit('timeline-save-request')
    },

    onRename() {
      this.$modal.show(
        PrevizRenameModal,
        {
          asset: this.sequence,
          project: this.project
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    },

    onDelete() {
      this.viewer.core.sequence.timeline.pause()

      this.$modal.show(
        AssetDeleteModal,
        {
          assets: [this.sequence],
          project: this.project
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    },

    toViewer() {
      this.$router.push({
        name: 'content_view',
        params: { uuid: this.sequence.id }
      })
    },

    onSelect() {
      this.busy = true
      this.$emit('select', this.sequence)
    }
  }
}
</script>
