<template>
  <div class="w-full">
    <form @submit.prevent="onSubmit">
      <div class="px-4 pt-4 md:px-6 md:pt-6">
        <!-- Modal Header -->
        <div
          class="flex flex-col items-center justify-start pb-4 border-b border-gray-200 dark:border-neutral-600 md:pb-6 sm:flex-row"
        >
          <div
            class="flex items-center justify-center flex-shrink-0 w-12 h-12 bg-red-100 rounded-full dark:bg-red-600 ring-2 ring-transparent dark:ring-red-400"
          >
            <fa-icon
              icon="trash-can"
              class="text-red-500 dark:text-red-100 fa-fw"
            />
          </div>
          <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg font-medium leading-6 text-gray-900 truncate dark:text-neutral-100"
              id="modal-title"
            >
              Delete Previz
            </h3>
            <div class="mt-2 truncate">
              <p class="text-sm text-gray-500 dark:text-neutral-400">
                Permanently deletes the selected Previz.
              </p>
            </div>
          </div>
        </div>

        <!-- Modal Content -->
        <div
          class="my-4 space-y-4 text-center md:space-y-6 md:my-6 sm:text-left"
        >
          <div
            class="p-4 mt-4 space-y-2 text-sm border border-gray-300 rounded-md dark:border-neutral-600"
          >
          <AssetItemThumb v-bind:asset="asset" />

          </div>
          <p
            class="text-sm text-center text-gray-500 sm:pt-0 sm:text-left dark:text-neutral-100"
          >
            Are you sure you want to delete the following asset
          </p>
        </div>
      </div>
    </form>
    <!-- Modal Footer -->
    <div
      class="flex flex-row p-4 space-x-4 bg-gray-100 border-t border-transparent dark:bg-neutral-800 dark:border-neutral-600 md:p-6 md:space-x-reverse md:flex-row-reverse md:justify-start"
    >
      <template v-if="form.busy">
        <button
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm cursor-wait shrink-0 md:flex-auto md:flex-grow-0 dark:text-neutral-100 dark:ring-offset-0 dark:bg-neutral-600 dark:border-neutral-400"
          type="button"
        >
          <fa-icon
            icon="spinner"
            spin
            class="my-auto mr-1 dark:text-neutral-100"
          />Working
        </button>
      </template>
      <template v-else>
        <button
          type="button"
          :class="{ loading: form.busy }"
          @click.prevent="onSubmit"
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm shrink-0 md:flex-auto md:flex-grow-0 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:text-neutral-100 dark:ring-offset-0 dark:bg-red-700 dark:hover:bg-red-600 dark:focus:ring-1 dark:focus:border-red-600 dark:focus:ring-red-400"
        >
          Delete Previz
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm dark:bg-transparent dark:text-neutral-400 dark:hover:text-neutral-100 dark:hover:border-neutral-400 dark:border dark:border-neutral-600 dark:rounded-md dark:hover:bg-neutral-600 shrink-0 md:flex-auto md:flex-grow-0 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>
      </template>
    </div>
  </div>
</template>

<script>
const AssetItemThumb = () => import('@components/assets/asset-item-thumb')

export default {
  name: 'asset-delete',

  props: ['asset'],
  components: {
    AssetItemThumb
  },
  data() {
    return {
      form: new SparkForm({})
    }
  },

  methods: {
    onSubmit() {
      this.form.startProcessing()

      // Delete asset(s)
      this.$store
        .dispatch('assets/delete', {
          assets: [this.asset]
        })
        .then(() => {
          this.form.finishProcessing()

          this.$emit('close')
        })
    }
  }
}
</script>
