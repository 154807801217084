<template>
  <div
    ref="ticksouter"
    class="flex justify-between items-center flex-1"
  >
    <div
      v-if="initd3"
      id="ticksvg"
      class="absolute w-full opacity-50"
    />
  </div>
</template>
<script>
import d3Ticks from '@mixins/d3-ticks'

export default {

  mixins: [ d3Ticks ],

  data () {
    return {
      outerHeight: 25,
      overallduration: 100
    }
  },

  computed: {
    width () {
      return this.outerWidth
    }
  },

  watch: {
    width (value) {
      this.initd3 = false
      this.delayedD3Draw()
    }
  },

  methods: {

  }
}
</script>
